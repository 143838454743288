<template>
  <div class="landing-page-menu-editor">
    <v-list>
      <v-list-group
        v-for="(item, index) in section.itensDeMenu"
        :id="item.title || item.menuTitle || item.nomeExibicao || item.titulo"
        :key="index"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.nomeExibicao || item.title || item.menuTitle || item.titulo }}
            </v-list-item-title>
            <v-list-item-subtitle>{{
              item.endereco || item.URLendereco || item.IndicadorSecao || item.Endereco
            }}</v-list-item-subtitle>
          </v-list-item-content>

          <!-- create a button to toggle active attribute -->

          <v-btn @click="toggleAtivar(item)" icon>
            <v-icon>{{
              item.ativo ? "mdi-toggle-switch" : "mdi-toggle-switch-off"
            }}</v-icon>
          </v-btn>

          <!-- add an edit button that uses the current menu item index to edit-->

          <v-btn @click="setEditableIndex(index)" icon>
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <!-- Subitens -->
        <v-list-item
          v-for="(subItem, subIndex) in item.itensDoSubMenu || item.subItems"
          :key="subIndex"
        >
          <v-list-item-content>
            <v-list-item-title>{{
              subItem.menuTitle || subItem.nomeExibicao
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              subItem.URLendereco || subItem.endereco
            }}</v-list-item-subtitle>
          </v-list-item-content>
          <!-- add and edit button that uses the current submenu item index to edit -->
          <v-btn @click="toggleAtivar(subItem)" icon>
            <v-icon>{{
              subItem.ativo ? "mdi-toggle-switch" : "mdi-toggle-switch-off"
            }}</v-icon>
          </v-btn>

          <v-btn
            v-if="
              item.constructor.name === 'ItemDeMenu' || item.itensDoSubMenu.length > 0
            "
            @click="setEditableSubMenuIndex(index, subIndex)"
            icon
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-list-item>

        <!-- Adicionar Submenu -->
        <v-btn
          v-if="item.constructor.name === 'ItemDeMenu' || item.itensDoSubMenu.length >= 0"
          @click="selecionarIndiceDoElementoEAbrirSubMenu"
        >
          Adicionar Submenu</v-btn
        >
      </v-list-group>
    </v-list>

    <v-btn @click="novoItemDeMenu.display = true">
      Adicionar Novo Menu <v-icon>mdi-plus-circle-outline</v-icon>
    </v-btn>

    <!-- Adicionar Novo Menu -->
    <v-dialog v-model="novoItemDeMenu.display" max-width="350">
      <v-card>
        <v-card-text class="mt-3">
          <v-text-field
            v-model="novoItemDeMenu.itemDeMenu.menuTitle"
            clearable
            solo
            rounded
            hide-details
            label="Título do Menu*"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="novoItemDeMenu.itemDeMenu.URLendereco"
            solo
            hide-details
            label="Endereço de redirecionamento"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn rounded text @click="novoItemDeMenu.display = false"> Fechar </v-btn>
          <v-btn color="green darken-1" rounded text @click="adicionarNovoItemDeMenu">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Editar Item de Menu-->
    <v-dialog v-model="editarItemDeMenu.visible" max-width="350">
      <v-card>
        <v-card-text class="mt-3">
          <v-text-field
            v-model="editarItemDeMenu.object.nomeExibicao"
            solo
            rounded
            hide-details
            label="Título do Menu*"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="editarItemDeMenu.object.endereco"
            solo
            hide-details
            label="Endereço de redirecionamento"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            text
            @click="
              editarItemDeMenu.editableIndex = undefined;
              editarItemDeMenu.visible = false;
            "
          >
            Fechar
          </v-btn>
          <v-btn color="green darken-1" rounded text @click="salvarModificacoes">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Adicionar Novo Item de Sub Menu -->
    <v-dialog v-model="novoItemDeSubMenu.display" max-width="350">
      <v-card>
        <v-card-text class="mt-3">
          <v-text-field
            v-model="novoItemDeSubMenu.itemDeSubMenu.menuTitle"
            solo
            rounded
            hide-details
            label="Título do SubMenu*"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="novoItemDeSubMenu.itemDeSubMenu.URLendereco"
            solo
            hide-details
            label="Endereço de redirecionamento"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn rounded text @click="novoItemDeSubMenu.display = false"> Fechar </v-btn>
          <v-btn color="green darken-1" rounded text @click="adicionarNovoItemDeSubMenu">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Editar Item De SubMenu -->
    <v-dialog v-model="editarItemDeSubMenu.visible" max-width="350">
      <v-card>
        <v-card-text class="mt-3">
          <v-text-field
            v-model="editarItemDeSubMenu.object.nomeExibicao"
            solo
            rounded
            hide-details
            label="Título do SubMenu*"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="editarItemDeSubMenu.object.endereco"
            solo
            hide-details
            label="Endereço de redirecionamento"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            text
            @click="
              editarItemDeSubMenu.editableIndex = undefined;
              editarItemDeSubMenu.visible = false;
            "
          >
            Fechar
          </v-btn>
          <v-btn color="green darken-1" rounded text @click="salvarModificacoesSubMenu">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex flex-row-reverse pt-6">
      <v-menu v-model="menuDelete" offset-y transition="slide-y-transition" :close-on-content-click="false">
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="error"
            rounded
            text
            class="text-capitalize mr-4"
            >Remover</v-btn
          >
        </template>
        <v-card max-width="300">
          <v-card-title class="text-h5"> Tem Certeza? </v-card-title>

          <v-card-text>
            Se você continuar, esta seção será removida da página inicial do sistema.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded text small class="py-4" @click="menuDelete = false">
              Desistir
            </v-btn>
            <v-btn color="error darken-1" rounded text small class="py-4" @click="remove">
              Remover
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { ItemDeMenu, Menu } from "components/LandingPage/LandingPageMenu";
import { EventBus } from "./EventBus.js";

export default {
  name: "LandingPageEditorMenuEditor",
  props: {
    section: {
      type: Menu,
      required: true,
    },
  },
  mounted() {
    console.log(this.section);
    EventBus.$emit("component-mounted", this.section);
  },
  data: () => {
    return {
      menuDelete: false,
      editarItemDeMenu: {
        visible: false,
        editableIndex: undefined,
        object: {
          nomeExibicao: "",
          endereco: "",
          ativo: true,
          itensDoSubMenu: [],
        },
      },
      editarItemDeSubMenu: {
        visible: false,
        editableIndex: undefined,
        object: {
          nomeExibicao: "",
          endereco: "",
          ativo: true,
          itensDoSubMenu: [],
        },
      },
      novoItemDeMenu: {
        display: false,
        itemDeMenu: new ItemDeMenu({ ativo: true }),
      },
      novoItemDeSubMenu: {
        display: false,
        editableIndex: undefined,
        itemDeSubMenu: new ItemDeMenu({ ativo: true }),
      },
    };
  },
  methods: {
    adicionarNovoItemDeMenu() {
      if (
        (this.novoItemDeMenu.itemDeMenu.menuTitle &&
          this.novoItemDeMenu.itemDeMenu.menuTitle.trim() == "") ||
        this.novoItemDeMenu.itemDeMenu.menuTitle == undefined
      ) {
        this.notifyError("O título do menu é obrigatório");
      } else {
        this.section.itensDeMenu.push(this.novoItemDeMenu.itemDeMenu);

        this.novoItemDeMenu.display = false;
        this.novoItemDeMenu.itemDeMenu = new ItemDeMenu({ ativo: true });
        EventBus.$emit("content-update", this.section);
      }
    },
    selecionarIndiceDoElementoEAbrirSubMenu(element) {
      const parentElementRootId =
        element.target.parentElement.parentElement.id ||
        element.srcElement.parentElement.parentElement.parentElement.id;
      const index = this.section.itensDeMenu.findIndex((item) => {
        return (
          item.menuTitle == parentElementRootId ||
          item.title == parentElementRootId ||
          item.nomeExibicao == parentElementRootId ||
          item.titulo == parentElementRootId
        );
      });
      this.novoItemDeSubMenu.editableIndex = index;
      this.novoItemDeSubMenu.display = true;
    },
    adicionarNovoItemDeSubMenu() {
      if (
        (this.novoItemDeSubMenu.itemDeSubMenu.menuTitle &&
          this.novoItemDeSubMenu.itemDeSubMenu.menuTitle.trim() == "") ||
        this.novoItemDeSubMenu.itemDeSubMenu.menuTitle == undefined
      ) {
        this.notifyError("O título do sub menu é obrigatório");
      } else {
        this.section.itensDeMenu[
          this.novoItemDeSubMenu.editableIndex
        ].itensDoSubMenu.push(this.novoItemDeSubMenu.itemDeSubMenu);
        this.novoItemDeSubMenu.display = false;
        this.novoItemDeSubMenu.editableIndex = undefined;
        this.novoItemDeSubMenu.itemDeSubMenu = new ItemDeMenu({ ativo: true });
        EventBus.$emit("content-update", this.section);
      }
    },
    remove() {
      this.$emit("on-remove", this.section);
    },
    setEditableIndex(index) {
      this.editarItemDeMenu.editableIndex = index;
      this.editarItemDeMenu.visible = true;
      this.editarItemDeMenu.object.nomeExibicao =
        this.section.itensDeMenu[this.editarItemDeMenu.editableIndex].nomeExibicao ||
        this.section.itensDeMenu[this.editarItemDeMenu.editableIndex].menuTitle;
      this.editarItemDeMenu.object.endereco =
        this.section.itensDeMenu[this.editarItemDeMenu.editableIndex].endereco ||
        this.section.itensDeMenu[this.editarItemDeMenu.editableIndex].URLendereco;
      this.editarItemDeMenu.object.itensDoSubMenu = this.section.itensDeMenu[
        this.editarItemDeMenu.editableIndex
      ].itensDoSubMenu;
      this.editarItemDeMenu.object.ativo = true;
    },
    salvarModificacoes() {
      if (
        this.editarItemDeMenu.object.nomeExibicao.trim() == "" ||
        this.editarItemDeMenu.object.nomeExibicao == undefined
      ) {
        this.notifyError("O título do menu é obrigatório");
      } else {
        this.section.itensDeMenu[
          this.editarItemDeMenu.editableIndex
        ] = this.editarItemDeMenu.object;
        this.editarItemDeMenu.editableIndex = undefined;
        this.editarItemDeMenu.visible = false;
        this.editarItemDeMenu.object = new Object();
      }
    },
    setEditableSubMenuIndex(index, subIndex) {
      this.editarItemDeMenu.editableIndex = index;
      this.editarItemDeSubMenu.editableIndex = subIndex;
      this.editarItemDeSubMenu.visible = true;
      this.editarItemDeSubMenu.object.nomeExibicao =
        this.section.itensDeMenu[this.editarItemDeMenu.editableIndex].itensDoSubMenu[
          this.editarItemDeSubMenu.editableIndex
        ].nomeExibicao ||
        this.section.itensDeMenu[this.editarItemDeMenu.editableIndex].itensDoSubMenu[
          this.editarItemDeSubMenu.editableIndex
        ].menuTitle;
      this.editarItemDeSubMenu.object.endereco =
        this.section.itensDeMenu[this.editarItemDeMenu.editableIndex].itensDoSubMenu[
          this.editarItemDeSubMenu.editableIndex
        ].endereco ||
        this.section.itensDeMenu[this.editarItemDeMenu.editableIndex].itensDoSubMenu[
          this.editarItemDeSubMenu.editableIndex
        ].URLendereco;
      this.editarItemDeSubMenu.object.itensDoSubMenu = this.section.itensDeMenu[
        this.editarItemDeMenu.editableIndex
      ].itensDoSubMenu[this.editarItemDeSubMenu.editableIndex].itensDoSubMenu;
      this.editarItemDeSubMenu.object.ativo = true;
    },
    salvarModificacoesSubMenu() {
      if (
        (this.editarItemDeSubMenu.object.nomeExibicao.trim() == "" ||
          this.editarItemDeSubMenu.object.nomeExibicao == undefined)
      ) {
        this.notifyError("O título do sub menu é obrigatório");
      } else {
        this.section.itensDeMenu[this.editarItemDeMenu.editableIndex].itensDoSubMenu[
          this.editarItemDeSubMenu.editableIndex
        ] = this.editarItemDeSubMenu.object;
        this.editarItemDeSubMenu.editableIndex = undefined;
        this.editarItemDeMenu.editableIndex = undefined;
        this.editarItemDeSubMenu.visible = false;
        this.editarItemDeSubMenu.object = new Object();
      }
    },
    toggleAtivar(item) {
      item.ativo = !item.ativo;
    },
  },
};
</script>
