import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page--register"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"py-0"},[_c(VBreadcrumbs,{staticClass:"pa-5",attrs:{"items":_vm.breadcrumbs}})],1)],1),_c(VRow,[_c(VCol,[_c(VForm,{ref:"form",staticClass:"px-10",attrs:{"autocomplete":"off"},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c(VFileInput,{attrs:{"multiple":"","counter":"","clearable":"","accept":"image/jpeg","show-size":"","disabled":_vm.isProcessing,"loading":_vm.isProcessing,"truncate-length":"15"},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c(VBtn,{attrs:{"text":"","color":"primary","disabled":!_vm.isFormValid || _vm.isProcessing,"loading":_vm.isProcessing},on:{"click":function($event){$event.preventDefault();return _vm.send.apply(null, arguments)}}},[_vm._v("Salvar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }