<template>
  <div class="page--permissions">
    <v-container fluid>
      <v-row>
        <v-col class="py-0">
          <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="landing-page-edition" cols="12" md="5" lg="4" xl="3">
          <div class="sticky">
            <lp-editor
              :landing-page-data="previewData"
              @sections-updated="updateSections"
              @on-save="onSave"
            ></lp-editor>
          </div>
        </v-col>

        <v-col class="landing-page-preview" cols="12" md="7" lg="8" xl="9">
          <v-card rounded="lg" class="pa-5">
            <div>
              <landing-page
                :preview="true"
                :preview-data="previewData"
              ></landing-page>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Cover } from "components/LandingPage/LandingPageCover";
import { Faq, FaqItem } from "components/LandingPage/LandingPageFaq";
import { Section } from "components/LandingPage/LandingPageSection";
import { Menu } from '~/components/LandingPage/LandingPageMenu';
import { ConsultarCredenciamento } from "~/components/LandingPage/LandingPageConsultarCredenciamento"

import LandingPage from "views/PreLogin/BaseLandingPage";
import LpEditor from "~/components/LandingPage/BaseLandingPageEditor";

import pageLoadingMixin from "mixins/page-loading";
import notificationMixin from "mixins/notification";

import configurations from "commons/configurations";

export default {
  name: "CustomizeLandingPage",
  components: { LandingPage, LpEditor },
  mixins: [pageLoadingMixin, notificationMixin],
  data: () => ({
    breadcrumbs: Object.freeze([
      {
        text: "Início",
      },
      {
        text: "Configuração",
      },
      {
        text: "Configurar Página Inicial",
      }
    ]),
    previewData: {
      app: {
        name: "Nome do Sistema",
        alias: "Sigla"
      },
      sections: [],
      showTopbarColor: true
    }
  }),
  beforeMount() {
    this.loadSections().then(() => {
      this.hidePageLoading();
    });
  },
  methods: {
    loadSections() {
      return this.$api()
        .get(configurations.api.home.sections)
        .then(({ data }) => {
          this.previewData.sections.length = 0;

          data.secoes
            .map(section => {
              switch (section.tipo) {
                case "capa":
                  return new Cover(section);
                case "faq": {
                  let faq = { ...section };
                  faq.items = [
                    new FaqItem({
                      id: 1,
                      title: "Primeira Pergunta?",
                      description: "Primeira resposta.",
                      tokens: ["Alguma pergunta relacionada", "alguma resposta relacionada", "algumas palavras relacionadas"]
                    }),
                    new FaqItem({
                      id: 2,
                      title: "Segunda Pergunta?",
                      description: "Segunda resposta.",
                      tokens: []
                    }),
                    new FaqItem({
                      id: 3,
                      title: "Terceira Pergunta?",
                      description: "Terceira resposta.",
                      tokens: []
                    }),
                    new FaqItem({
                      id: 4,
                      title: "Quarta Pergunta?",
                      description: "Quarta resposta.",
                      tokens: []
                    }),
                    new FaqItem({
                      id: 5,
                      title: "Quinta Pergunta?",
                      description: "Quinta resposta.",
                      tokens: []
                    }),
                  ];
                  return new Faq(faq);
                }
                case "menu":
                  return new Menu(section);
                case "consultarCredenciamento":
                  return new ConsultarCredenciamento(section)
                default:
                  return new Section(section);
              }
            })
            .forEach(section => {
              this.previewData.sections.push(section);
            });
        });
    },
    updateSections(sections) {
      this.previewData.sections = sections;
    },
    onSave(sections) {
      console.log(sections);
      console.log(sections.map(s => s.toApi()));
      this.showPageLoading();
      this.$api()
        .put(configurations.api.home.sections, { secoes: sections.map(s => s.toApi())})
        .then(() => {
          this.hidePageLoading();
          this.notifySuccess("Página inicial atualizada com sucesso");
        }).catch(({ response }) => {
          this.hidePageLoading();

          if (response && response.data) {
            this.notifyError(response.data.mensagem);
          } else {
            this.notifyError(
              "Não conseguimos atualizar a página inicial."
            );
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep.thumbnail {
  border: 1px solid #ddd;
  border-radius: 10cm;

  .v-image__image--cover {
    border: 5px solid #fff;
    border-radius: 10cm;
  }
}
.sticky {
  position: sticky;
  top: 75px;
}
</style>