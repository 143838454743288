export class SectionResponse {
  constructor(response) {
    this.titulo = response?.titulo;
    this.menuTitle = response?.menuTitle;
    this.title = response?.title;
    this.acesso = response?.acesso;
    this.altura = response?.altura;
    this.conteudo = response?.conteudo;
    this.enderecoDaImagem = response?.enderecoDaImagem;
    this.enderecoDaImagemDoLogotipo = response?.enderecoDaImagemDoLogotipo;
    this.escuro = response?.escuro;
    this.imagemNoLadoEsquerdo = response?.imagemNoLadoEsquerdo;
    this.indicadorDireito = response?.indicadorDireito;
    this.indicadorDoMeio = response?.indicadorDoMeio;
    this.indicadorEsquerdo = response?.indicadorEsquerdo;
    this.indicadorSecao = response?.indicadorSecao || response?.IndicadorSecao;
    this.recursos = response?.recursos;
    this.segundoTexto = response?.segundoTexto;
    this.terceiroTexto = response?.terceiroTexto;
    this.tipo = response?.tipo;
    this.nomeExibicao = response?.nomeExibicao;
    this.menus = response?.menus;
    this.ativo = response?.ativo;
  }
}
