import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"d-flex flex-row-reverse pt-6"},[_c(VMenu,{attrs:{"offset-y":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"text-capitalize mr-4",attrs:{"color":"error","rounded":"","text":""}},'v-btn',attrs,false),on),[_vm._v("Remover")])]}}]),model:{value:(_vm.menuDelete),callback:function ($$v) {_vm.menuDelete=$$v},expression:"menuDelete"}},[_c(VCard,{attrs:{"max-width":"300"}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Tem Certeza? ")]),_c(VCardText,[_vm._v(" Se você continuar, esta seção será removida da página inicial do sistema. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"py-4",attrs:{"rounded":"","text":"","small":""},on:{"click":function($event){_vm.menuDelete = false}}},[_vm._v(" Desistir ")]),_c(VBtn,{staticClass:"py-4",attrs:{"color":"error darken-1","rounded":"","text":"","small":""},on:{"click":_vm.remove}},[_vm._v(" Remover ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }