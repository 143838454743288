import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"rounded":"lg"}},[_c(VToolbar,{attrs:{"dense":"","elevation":"1"}},[(_vm.editing.section)?_c(VBtn,{attrs:{"rounded":"","plain":"","small":""},on:{"click":function($event){$event.preventDefault();return _vm.clearEdition.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1):_vm._e(),_c(VToolbarTitle,{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.title))]),_c(VSpacer),(!_vm.editing.section)?_c(VBtn,{staticClass:"text-capitalize",attrs:{"rounded":"","small":"","outlined":"","color":"success"},on:{"click":_vm.onSaveClick}},[_vm._v("Salvar")]):_vm._e(),(!_vm.editing.section)?_c(VMenu,{attrs:{"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","plain":"","ripple":false}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-plus-circle-outline")])],1)]}}],null,false,1983714284)},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.sectionTypeConfigurations),function(configuration,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.addSection(configuration)}}},[_c(VListItemAvatar,[_c(VBtn,{attrs:{"icon":"","x-small":""}},[_c(VIcon,[_vm._v(_vm._s(configuration.icon))])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(configuration.title))])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":"","small":"","plain":""}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)],1)}),1)],1):_vm._e()],1),(!_vm.editing.section)?_c(VCol,[_c(VRow,[_c(VCol,[_c(VList,{attrs:{"dense":""}},[_c('draggable',{staticClass:"list-group",attrs:{"tag":"ul","list":_vm.landingPageData.sections,"handle":".drag"}},_vm._l((_vm.sectionConfigurationsList),function(configuration,index){return _c(VListItem,{key:index},[_c(VListItemAvatar,{staticClass:"drag"},[_c(VBtn,{attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v("mdi-drag")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(configuration.title))])],1),_c(VListItemAction,[_c(VItemGroup,[_c(VBtn,{attrs:{"rounded":"","plain":"","ripple":"","disabled":!configuration.editable},on:{"click":function($event){return _vm.editSectionAt(index)}}},[(configuration.editable)?_c(VIcon,[_vm._v("mdi-square-edit-outline")]):_c(VIcon,[_vm._v("mdi-block-helper")])],1)],1)],1)],1)}),1)],1)],1)],1)],1):_c(VCol,[(_vm.sectionEditor)?_c(_vm.sectionEditor,{tag:"component",attrs:{"section":_vm.editing.section},on:{"on-remove":_vm.onRemoveSection}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }