<template>
  <section>
    <div class="d-flex flex-row-reverse pt-6">
      <v-menu
        v-model="menuDelete"
        offset-y
        transition="slide-y-transition"
        :close-on-content-click="false"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="error"
            rounded
            text
            class="text-capitalize mr-4"
            >Remover</v-btn
          >
        </template>
        <v-card max-width="300">
          <v-card-title class="text-h5"> Tem Certeza? </v-card-title>

          <v-card-text>
            Se você continuar, esta seção será removida da página inicial do sistema.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded text small class="py-4" @click="menuDelete = false">
              Desistir
            </v-btn>
            <v-btn color="error darken-1" rounded text small class="py-4" @click="remove">
              Remover
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
  </section>
</template>

<script>
import { ConsultarCredenciamento } from "./LandingPageConsultarCredenciamento.vue";

export default {
  name: "LandingPageEditorConsultarCredenciamentoEditor",
  data: () => ({
    menuDelete: false,
  }),
  props: {
    section: {
      type: ConsultarCredenciamento,
      required: true,
    },
  },
  methods: {
    remove() {
      this.$emit("on-remove", this.section);
    },
  },
};
</script>
