import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"landing-page-faq-editor"},[_c(VForm,{staticClass:"pb-6"},[_c('div',{staticClass:"inputs pl-6 pt-6"},[_c('div',{staticClass:"d-flex align-center"},[_c(VTextField,{attrs:{"solo":"","rounded":"","hide-details":"","label":"Menu","disabled":!_vm.configurations.menuTitle.visible},on:{"input":function($event){return _vm.onChange('menuTitle', $event)}},model:{value:(_vm.theSection.menuTitle),callback:function ($$v) {_vm.$set(_vm.theSection, "menuTitle", $$v)},expression:"theSection.menuTitle"}}),_c(VSwitch,{staticClass:"ml-6",attrs:{"inset":""},on:{"change":function($event){return _vm.toggle('menuTitle', $event)}},model:{value:(_vm.configurations.menuTitle.visible),callback:function ($$v) {_vm.$set(_vm.configurations.menuTitle, "visible", $$v)},expression:"configurations.menuTitle.visible"}})],1),_c('div',{staticClass:"d-flex align-center"},[_c(VTextField,{attrs:{"solo":"","rounded":"","hide-details":"","label":"Título","disabled":_vm.configurations.title.visible},on:{"input":function($event){return _vm.onChange('title', $event)}},model:{value:(_vm.theSection.title),callback:function ($$v) {_vm.$set(_vm.theSection, "title", $$v)},expression:"theSection.title"}})],1)]),_c('div',{staticClass:"d-flex flex-row-reverse pt-6"},[_c(VMenu,{attrs:{"offset-y":"","transition":"slide-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"text-capitalize mr-4",attrs:{"color":"error","rounded":"","text":""}},'v-btn',attrs,false),on),[_vm._v("Remover")])]}}]),model:{value:(_vm.menuDelete),callback:function ($$v) {_vm.menuDelete=$$v},expression:"menuDelete"}},[_c(VCard,{attrs:{"max-width":"300"}},[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Tem Certeza? ")]),_c(VCardText,[_vm._v(" Se você continuar, esta seção será removida da página inicial do sistema. ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"py-4",attrs:{"rounded":"","text":"","small":""},on:{"click":function($event){_vm.menuDelete = false}}},[_vm._v(" Desistir ")]),_c(VBtn,{staticClass:"py-4",attrs:{"color":"error darken-1","rounded":"","text":"","small":""},on:{"click":_vm.remove}},[_vm._v(" Remover ")])],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }