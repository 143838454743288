<template>
  <v-card rounded="lg">
    <v-toolbar dense elevation="1">
      <v-btn v-if="editing.section" rounded plain small @click.prevent="clearEdition">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title class="subtitle-2">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!editing.section"
        rounded
        small
        outlined
        color="success"
        class="text-capitalize"
        @click="onSaveClick"
        >Salvar</v-btn
      >
      <v-menu v-if="!editing.section" offset-y transition="slide-y-transition">
        <template #activator="{ on, attrs }">
          <v-btn small plain :ripple="false" v-bind="attrs" v-on="on">
            <v-icon>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            @click="addSection(configuration)"
            v-for="(configuration, index) in sectionTypeConfigurations"
            :key="index"
          >
            <v-list-item-avatar>
              <v-btn icon x-small>
                <v-icon>{{ configuration.icon }}</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ configuration.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon small plain>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-col v-if="!editing.section">
      <v-row>
        <v-col>
          <v-list dense>
            <draggable
              tag="ul"
              :list="landingPageData.sections"
              class="list-group"
              handle=".drag"
            >
              <v-list-item
                v-for="(configuration, index) in sectionConfigurationsList"
                :key="index"
              >
                <v-list-item-avatar class="drag">
                  <v-btn icon small>
                    <v-icon>mdi-drag</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ configuration.title }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-item-group>
                    <v-btn
                      rounded
                      plain
                      ripple
                      @click="editSectionAt(index)"
                      :disabled="!configuration.editable"
                    >
                      <v-icon v-if="configuration.editable"
                        >mdi-square-edit-outline</v-icon
                      >
                      <v-icon v-else>mdi-block-helper</v-icon>
                    </v-btn>
                  </v-item-group>
                </v-list-item-action>
              </v-list-item>
            </draggable>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else>
      <component
        v-if="sectionEditor"
        :is="sectionEditor"
        :section="editing.section"
        @on-remove="onRemoveSection"
      ></component>
    </v-col>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";

import { Cover } from "components/LandingPage/LandingPageCover";
import { Section } from "components/LandingPage/LandingPageSection";
import { Faq, FaqItem } from "components/LandingPage/LandingPageFaq";
import { Menu, ItemDeMenu } from "components/LandingPage/LandingPageMenu";
import { SectionResponse } from "./responseObject.js";
import { ConsultarCredenciamento } from './LandingPageConsultarCredenciamento.vue';

import LandingPageEditorCoverEditor from "components/LandingPage/LandingPageEditorCoverEditor";
import LandingPageEditorSectionEditor from "components/LandingPage/LandingPageEditorSectionEditor";
import LandingPageEditorFaqEditor from "components/LandingPage/LandingPageEditorFaqEditor";
import LandingPageEditorMenuEditor from "components/LandingPage/LandingPageEditorMenuEditor";
import LandingPageEditorConsultarCredenciamentoEditor from './LandingPageEditorConsultarCredenciamentoEditor.vue';

export default {
  name: "BaseLandingPageEditor",
  components: {
    draggable,
    LandingPageEditorCoverEditor,
    LandingPageEditorSectionEditor,
    LandingPageEditorFaqEditor,
    LandingPageEditorMenuEditor,
    LandingPageEditorConsultarCredenciamentoEditor
  },
  props: {
    landingPageData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    sectionTypeConfigurations: Object.freeze([
      {
        type: "cover",
        icon: "mdi-image-area",
        title: "Capa",
        editable: true,
      },
      {
        type: "regular",
        icon: "mdi-text-box-outline",
        title: "Seção",
        editable: true,
      },
      {
        type: "faq",
        icon: "mdi-help-circle-outline",
        title: "FAQ",
        editable: true,
      },
      {
        disabled: false,
        type: "menu",
        icon: "mdi-menu",
        title: "Barra de Menu",
        editable: true,
      },
      {
        type: "consultarCredenciamento",
        icon: "mdi-information-outline",
        title: "Consulta de Credenciamento",
        editable: true
      }
    ]),
    editing: {
      index: -1,
      section: undefined,
    },
  }),
  computed: {
    /**
     * Obtém o nome do componente editor da seção maracada para edição
     */
    sectionEditor() {
      const components = {
        cover: "LandingPageEditorCoverEditor",
        faq: "LandingPageEditorFaqEditor",
        regular: "LandingPageEditorSectionEditor",
        menu: "LandingPageEditorMenuEditor",
        consultarCredenciamento: "LandingPageEditorConsultarCredenciamentoEditor"
      };

      if (this.editing && this.editing.section) {
        return components[this.editing.section.type];
      }

      return undefined;
    },
    /**
     * Título da barra de ferramentas
     */
    title() {
      return this.editing.section
        ? this.getSectionConfiguration(this.editing.section).title
        : "Personalizar";
    },
    /**
     * Lista as configurações das seções adicionadas
     */
    sectionConfigurationsList() {
      return this.landingPageData.sections.map((section) =>
        this.getSectionConfiguration(section)
      );
    },
  },
  methods: {
    /**
     * Obtém a configuração da seção
     */
    getSectionConfiguration(section) {
      return this.sectionTypeConfigurations.find(
        (configuration) => configuration.type === section.type
      );
    },
    /**
     * Adicionar uma nova seção à Landing Page com o conteúdo padrão.
     */
    addSection(configuration) {
      if (
        configuration.type == "menu" &&
        this.landingPageData.sections.filter((section) => section.type == "menu").length
      )
        this.notifyError("Já existe um menu cadastrado");
      else {
        if (configuration.type === "cover") {
          this.landingPageData.sections.push(new Cover());
        } else if (configuration.type === "regular") {
          this.landingPageData.sections.push(new Section());
        } else if (configuration.type === "faq") {
          const faqItem1 = new FaqItem({
            id: 1,
            title: "Pergunta 1",
            description: "Resposta 1",
            tokens: ["Alguma pergunta relacionada"],
          });
          const faqItem2 = new FaqItem({
            id: 2,
            title: "Pergunta 2",
            description: "Resposta 2",
            tokens: ["Outra pergunta relacionada"],
          });
          this.landingPageData.sections.push(
            new Faq({ menuTitle: "FAQ", items: [faqItem1, faqItem2] })
          );
        } else if (configuration.type === "menu") {
          console.log(this.landingPageData.sections);
          this.landingPageData.sections.push(
            new Menu({
              title: "Menu",
              itensDeMenu: [
                ...this.landingPageData.sections.map(
                  (section) => new ItemDeMenu(new SectionResponse(section))
                ),
              ],
            })
          );
        } else if (configuration.type === "consultarCredenciamento") {
          this.landingPageData.sections.push(
            new ConsultarCredenciamento()
          )
        }

        this.$emit("sections-updated", this.landingPageData.sections);
      }
    },
    /**
     * Marca a seção, num determinado indice da lista, para edição.
     * Caso haja alguma outra já em edição ela será substituída
     */
    editSectionAt(sectionIndex) {
      if (
        sectionIndex > this.landingPageData.sections.length - 1 ||
        !this.getSectionConfiguration(this.landingPageData.sections[sectionIndex])
          .editable
      ) {
        this.clearEdition();
      } else {
        this.editing.index = sectionIndex;
        this.editing.section = this.landingPageData.sections[sectionIndex];
      }
    },
    /**
     * Remove a seção da edição
     */
    clearEdition() {
      this.editing.index = -1;
      this.editing.section = undefined;
    },
    /**
     * Remove da lista a seção que está em edição
     */
    onRemoveSection() {
      this.landingPageData.sections.splice(this.editing.index, 1);
      this.clearEdition();
    },
    /**
     * Dispara o evento "onsave" com a lista de seções configuradas como parametro
     */
    onSaveClick() {
      if (
        Array.isArray(this.landingPageData.sections) &&
        this.landingPageData.sections.length
      ) {
        this.landingPageData.sections.forEach((section, index) => {
          section.IndicadorSecao = `#secao-${index + 1}`;
        });

        this.$emit("on-save", this.landingPageData.sections);
      } else {
        this.$emit("on-save", []);
      }
    },
  },
};
</script>
