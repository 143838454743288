<template>
  <div class="landing-page-cover-editor">
    <v-form class="pb-6">
      <div class="inputs pl-6 pt-6">
        <div class="d-flex align-center">
          <v-text-field
            v-model="theSection.coverSrc"
            solo
            rounded
            hide-details
            label="URL da Imagem"
          ></v-text-field>
          <v-switch disabled inset class="ml-6" :input-value="true"></v-switch>
        </div>

        <div class="d-flex align-center">
          <v-select
            v-model="theSection.dark"
            :items="configurations.dark.options"
            item-text="title"
            item-value="value"
            solo
            rounded
            hide-details
            label="Claridade"
          ></v-select>
          <v-switch :input-value="true" inset class="ml-6" disabled></v-switch>
        </div>

        <div class="d-flex align-center">
          <v-text-field
            v-model="theSection.logoSrc"
            solo
            rounded
            hide-details
            label="URL do Logotipo"
            :disabled="!configurations.logoSrc.visible"
            @input="onChange('logoSrc', $event)"
          ></v-text-field>
          <v-switch
            v-model="configurations.logoSrc.visible"
            inset
            class="ml-6"
            @change="toggle('logoSrc', $event)"
          ></v-switch>
        </div>

        <div class="d-flex align-center">
          <v-text-field
            v-model="theSection.title"
            solo
            rounded
            hide-details
            label="Título"
            :disabled="!configurations.title.visible"
            @input="onChange('title', $event)"
          ></v-text-field>
          <v-switch
            v-model="configurations.title.visible"
            inset
            class="ml-6"
            @change="toggle('title', $event)"
          ></v-switch>
        </div>

        <div class="d-flex align-center">
          <v-text-field
            v-model="theSection.secondText"
            solo
            rounded
            hide-details
            label="Informação"
            :disabled="!configurations.secondText.visible"
            @input="onChange('secondText', $event)"
          ></v-text-field>
          <v-switch
            v-model="configurations.secondText.visible"
            inset
            class="ml-6"
            @change="toggle('secondText', $event)"
          ></v-switch>
        </div>

        <div class="d-flex align-center">
          <v-text-field
            v-model="theSection.thirdText"
            solo
            rounded
            hide-details
            label="Descrição"
            :disabled="!configurations.thirdText.visible"
            @input="onChange('thirdText', $event)"
          ></v-text-field>
          <v-switch
            v-model="configurations.thirdText.visible"
            inset
            class="ml-6"
            @change="toggle('thirdText', $event)"
          ></v-switch>
        </div>

        <div class="d-flex align-center">
          <v-text-field
            v-model="theSection.loginButton.title"
            solo
            rounded
            hide-details
            label="URL do Logotipo"
            :disabled="!configurations.loginButton.title.visible"
            @input="onChange('loginButton.title', $event)"
          ></v-text-field>
          <v-switch
            v-model="configurations.loginButton.title.visible"
            inset
            class="ml-6"
            @change="toggle('loginButton.title', $event)"
          ></v-switch>
        </div>

        <div class="d-flex align-center">
          <v-text-field
            v-model="theSection.loginButton.path"
            solo
            rounded
            hide-details
            label="URL de Acesso"
            :disabled="!configurations.loginButton.path.visible"
            @input="onChange('loginButton.path', $event)"
          ></v-text-field>
          <v-switch disabled inset class="ml-6" :input-value="true"></v-switch>
        </div>
      </div>

      <div class="d-flex flex-row-reverse pt-6">
        <v-menu
          v-model="menuDelete"
          offset-y
          transition="slide-y-transition"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="error"
              rounded
              text
              class="text-capitalize mr-4"
              >Remover</v-btn
            >
          </template>
          <v-card max-width="300">
            <v-card-title class="text-h5"> Tem Certeza? </v-card-title>

            <v-card-text>
              Se você continuar, esta seção será removida da página inicial do
              sistema.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                rounded
                text
                small
                class="py-4"
                @click="menuDelete = false"
              >
                Desistir
              </v-btn>
              <v-btn
                color="error darken-1"
                rounded
                text
                small
                class="py-4"
                @click="remove"
              >
                Remover
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </v-form>
  </div>
</template>

<script>
import { get as _get, set as _set } from "lodash";

import { Cover } from "components/LandingPage/LandingPageCover";

export default {
  name: "LandingPageEditorCoverEditor",
  props: {
    section: {
      type: Cover,
      required: true
    }
  },
  data: () => ({
    menuDelete: false,
    theSection: new Cover(),
    configurations: {
      logoSrc: {
        visible: true,
        value: undefined
      },
      dark: {
        value: undefined,
        options: [
          { title: "Escuro", value: true },
          { title: "Claro", value: false }
        ]
      },
      title: {
        visible: true,
        value: undefined
      },
      secondText: {
        visible: true,
        value: undefined
      },
      thirdText: {
        visible: true,
        value: undefined
      },
      loginButton: {
        title: {
          visible: true,
          value: undefined
        },
        path: {
          visible: true,
          value: undefined
        }
      }
    }
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.theSection = this.section;

      this.confitureItem("logoSrc");
      this.confitureItem("title");
      this.confitureItem("secondText");
      this.confitureItem("thirdText");
      this.confitureItem("loginButton.title");
      this.confitureItem("loginButton.path");
    },
    confitureItem(modelPath) {
      const model = _get(this.theSection, modelPath);
      const configuration = _get(this.configurations, modelPath);

      configuration.visible = typeof model === "string" && model.length > 0;
      configuration.value = model;
    },
    remove() {
      this.$emit("on-remove", this.section);
    },
    toggle(modelPath, visible) {
      const configuration = _get(this.configurations, modelPath);
      configuration.visible = visible;

      if (visible) {
        _set(this.theSection, modelPath, configuration.value);
      } else {
        _set(this.theSection, modelPath, undefined);
      }
    },
    onChange(convigurationPath, value) {
      _set(this.configurations, convigurationPath + ".value", value);
    }
  }
};
</script>

<style>
</style>