<template>
  <div class="page--register">
    <v-container fluid>
      <v-row>
        <v-col class="py-0">
          <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-form
            ref="form"
            v-model="isFormValid"
            class="px-10"
            autocomplete="off"
          >
            <v-file-input
              v-model="files"
              multiple
              counter
              clearable
              accept="image/jpeg"
              show-size
              :disabled="isProcessing"
              :loading="isProcessing"
              truncate-length="15"
            ></v-file-input>
            <v-btn
              text
              color="primary"
              :disabled="!isFormValid || isProcessing"
              :loading="isProcessing"
              @click.prevent="send"
              >Salvar</v-btn
            >
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import pageLoadingMixin from "mixins/page-loading";

export default {
  name: "Register",
  mixins: [pageLoadingMixin],
  data: () => ({
    breadcrumbs: [
      {
        text: "Início",
        to: "/"
      },
      {
        text: "Exemplos",
        to: "/exemplos/listar",
      },
      {
        text: "Exemplo de Envio de Arquivos",
        to: "/exemplos/enviararquivos"
      }
    ],
    isFormValid: undefined,
    isProcessing: false,
    files: []
  }),
  beforeMount() {
    this.hidePageLoading();
  },
  methods: {
    send() {
      this.$refs.form.validate();
      this.isProcessing = true;

      if (this.isFormValid) {
        const data = this.getFormData();

        this.$api()
          .post("/v1/exemplos/enviararquivos", data)
          .then(() => {
            this.isProcessing = false;
            console.log("Enviou");
          })
          .catch(() => {
            this.isProcessing = false;
            console.log("Não Enviou");
          });
      }
    },
    /*
     * Cria o objeto FormData que será enviado para o servidor.
     * Todos os parâmetros devem estar neste objeto.
     */
    getFormData() {
      let data = new FormData();
      for (let file of this.files) {
        data.append("anexos", file);
      }
      data.append("OutroParametro", "Outro valor");

      return data;
    }
  }
};
</script>